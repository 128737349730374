import { useEffect, useRef, useState } from "react";
import { useWindow } from "context/windowContext";
import { useCustomer } from "context/customerContext";
import axios from "axios";

const useMyAccountTreatment = (props) => {
    const { products } = props;

    const { window } = useWindow();
    const { customerData } = useCustomer();

    const abortControllerRef = useRef(new AbortController());

    const [treatmentId, setTreatmentId] = useState("");
    const [treatmentData, setTreatmentData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isTreatmentLoad, setIsTreatmentLoad] = useState(true);
    const [isSubscriptionLoad, setIsSubscriptionLoad] = useState(true);
    const [dealsCountToShow, setDealsCountToShow] = useState(1);
    const [subscriptionsCountToShow, setSubscriptionsCountToShow] = useState(1);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const treatmentNameMap = {
        "checking-type-hair-treatment": "Haarverlies",
        "checking-type-pe": "Vroegtijdige Ejaculatie",
        "checking-type-ed": "Erectiestoornis",
        "checking-id": "ID verification",
    };

    const treatmentBigcommerceCategoryMap = {
        "checking-type-hair-treatment": 24,
        "checking-type-pe": 33,
        "checking-type-ed": 32,
    };

    const getTreatmentData = () => {
        if (!customerData.id) {
            return;
        }

        axios
            .get("/api/zendesk/chat/getmessages", {
                params: {
                    customer_id: customerData.id,
                    type: treatmentId,
                },
                signal: abortControllerRef.current.signal
            })
            .then((response) => {
                setTreatmentData(response.data[treatmentId]);
                setIsTreatmentLoad(false);
            })
            .catch(e => e.code !== "ERR_CANCELED" && console.error(e));

        axios.get("/api/zendesk/chat/messagecount/get", {
            params: {
                customer_id: customerData.id,
                type: treatmentId
            },
        }).then(({ data }) => {
            setUnreadMessagesCount(+data[treatmentId])
        }).catch(e => console.error(e));
    };

    const getSubscriptions = () => {
        if (!customerData.id) {
            return;
        }

        const chargebeeID = (customerData?.form_fields || []).find(
            (element) => element.name === "chargebee_id"
        );

        if (!chargebeeID.value) {
            setIsSubscriptionLoad(false);
            return;
        }

        axios
            .get("/api/chargebee/v2/subscription", {
                params: {
                    customer_id: chargebeeID.value,
                },
                signal: abortControllerRef.current.signal
            })
            .then((response) => {
                setSubscriptions(response?.data?.result);
                setIsSubscriptionLoad(false);
            })
            .catch(e => e.code !== "ERR_CANCELED" && console.error(e));
    };

    const editPopUpMessage = async (e) => {
        e.preventDefault();
        try {
            const inputs = Array.from(e.target.querySelectorAll("textarea"));

            if (inputs[0].value === '') {
                return;
            }

            const promise = axios.post("/api/zendesk/create_ticket", {
                question:inputs[0].value,
                type:inputs[1].value,
                "customer_id":customerData.id
            });

            // await toast.promise(promise, {
            //     pending: "Sending...",
            // });
            console.log("Sending...");
            setShowPopUp(false);
        } catch (e) {
            console.error(e);
        }
    };


    const chatAdminUser = treatmentData?.users.find(
        (user) => user.role === "admin"
    );

    const filteredDeals = treatmentData?.deals.filter((item) => {
        return subscriptions.find(
            (el) => el.subscription.id === item.data.custom_fields.subscription_id
        );
    });

    const dealsToShow = (filteredDeals || []).slice(0, dealsCountToShow);

    const filteredSubscriptions = subscriptions
        .filter(
            (item) =>
                item.subscription.status !== "non_renewing" &&
                item.subscription.status !== "cancelled"
        )
        .filter((item) => {
            const productID = item?.subscription?.products[0]?.product_id;
            const product = products.find((el) => el.bigcommerce_id === productID);

            return product.categories.includes(
                treatmentBigcommerceCategoryMap[treatmentId]
            );
        });

    const subscriptionsToShow = filteredSubscriptions.slice(0, subscriptionsCountToShow);
    const isLoad = isTreatmentLoad || isSubscriptionLoad;

    useEffect(() => {
        const currLocationParams = new URLSearchParams(window.location?.search);
        setTreatmentId(currLocationParams.get("treatmentId"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getTreatmentData(signal);
        getSubscriptions(signal);

        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treatmentId, customerData?.id]);

    return {
        treatmentData,
        isLoad,
        treatmentId,
        chatAdminUser,
        dealsToShow,
        dealsCountToShow,
        setDealsCountToShow,
        subscriptionsToShow,
        subscriptionsCountToShow,
        setSubscriptionsCountToShow,
        unreadMessagesCount,
        subscriptions,
        filteredSubscriptions,
        treatmentNameMap,
        filteredDeals,
        showPopUp, 
        setShowPopUp,
        selectedTitle, 
        setSelectedTitle,
        editPopUpMessage
    };
};

export default useMyAccountTreatment;
