import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Image from "components/UI/image";

import "styles/dropdown.scss";

import DropdownArrow from "images/svg/dropdownArrow.svg";

const DropdownItem = (props) => {
    const { name, text, richText, isOpenId, setIsOpenId, id } = props;
    const isOpenDropdown = (isOpenId === id);
    const clickHandler = () => {
        if (isOpenDropdown) {
            setIsOpenId(null);
        } else {
            setIsOpenId(id);
        }
    };

    return (
        <div className="dropdown__item" onClick={clickHandler}>
            <div className="dropdown__item-button">
                <p className="dropdown__item-button__title">{name}</p>
                <div className="dropdown__item-button__arrow-container">
                    <Image
                        className={`dropdown-arrow ${isOpenDropdown ? "dropdown-arrow-active" : ""}`}
                        src={DropdownArrow}
                        alt={isOpenDropdown ? "Close" : "Open"}
                    />
                </div>
            </div>
            <p className={isOpenDropdown ? "dropdown__item-content" : "dropdown__item-content-close"}>
                {richText ? renderRichText(richText) : text}
            </p>
        </div>
    );
};

export default DropdownItem;
