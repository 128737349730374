import React, { useMemo } from "react";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";

import { isNotEmpty } from "utils/helper";
import useMyAccountTreatment from "hooks/myAccount/useMyAccountTreatment";
import useMyAccount from "hooks/myAccount/useMyAccount";
import useValidation from "hooks/useValidation";

import Main from "components/Layout/main";
import Button from "components/UI/button";
import Dropdown from "components/UI/dropdown";
import Loader from "components/UI/loader";
import Image from "components/UI/image";

import ArrowMobile from "images/svg/my-account-arrow-mobile.svg";
import ArrowDesktop from "images/svg/my-account-arrow-desktop.svg";
import BackArrow from "images/svg/buttonArrowDark.svg";
import DoctorAvatar from "images/doctor-avatar-sample.png";
import Thumbnail from "images/thumbnail-sample.png";
import { t } from "utils/translate";

import "styles/treatment-page.scss";

const faqQuery = graphql`
  query FaqQuery {
    allContentfulBannerUpdated(filter: { location: { eq: "treatments" } }) {
      nodes {
        title
        bulletListText {
          id
          dropdownTitle
          text
        }
      }
    }
    allBigCommerceProducts {
      nodes {
        name
        bigcommerce_id
        categories
        images {
          is_thumbnail
          url_thumbnail
          url_standard
        }
      }
    }
  }
`;

const TreatmentsWrapper = (props) => {
    const { allContentfulBannerUpdated, allBigCommerceProducts } = useStaticQuery(faqQuery);

    const products = allBigCommerceProducts.nodes;

    const {
        treatmentData,
        isLoad,
        treatmentId,
        chatAdminUser,
        dealsToShow,
        dealsCountToShow,
        setDealsCountToShow,
        subscriptionsToShow,
        subscriptionsCountToShow,
        setSubscriptionsCountToShow,
        unreadMessagesCount,
        subscriptions,
        filteredSubscriptions,
        treatmentNameMap,
        filteredDeals,
        showPopUp, 
        setShowPopUp,
        selectedTitle, 
        setSelectedTitle,
        editPopUpMessage
    } = useMyAccountTreatment({ products });

    const { tickets } = useMyAccount();
    const { validation } = useValidation();
    const createdAt = useMemo(() => new Date(
        tickets.find((t) => t.checking_type === treatmentId)?.created_at || null
    ).toLocaleDateString(), [tickets, treatmentId]);

    const hideAndResetPopUp = () => {
        setShowPopUp(false);
        setSelectedTitle("")
    };

    const handleButtonClick = (title) => {
        console.log(123)
        setSelectedTitle(title);
        setShowPopUp(true);
    };

    if (isLoad) {
        return <Loader/>
    }

    const firstComment = treatmentData.comments.find(Boolean);

    return (
        <div className="treatment">
            <div className="treatment__header">
                <div className="treatment__header--back" onClick={() => navigate("/my-account")}>
                    <Image className="back-icon" src={BackArrow}/>
                    <p className="typography__p">Terug</p>
                </div>
                <h2 className="treatment__header--title">
                    {treatmentNameMap[treatmentId]}
                </h2>
                {
                    isNotEmpty(tickets) && (
                        <p className="treatment__header--subtext">
                            Actief sinds{" "}{createdAt}
                        </p>
                    )
                }
            </div>

            <div className="treatment__subscriptions">
                {subscriptionsToShow.map(({ subscription }) => {
                    const currentProduct = products.find(
                        (item) =>
                            item.bigcommerce_id === subscription?.products[0]?.product_id
                    );

                    const dosage = subscription.products[0].product_options.find(
                        (el) => el.display_name === "Dosage"
                    );
                    const quantity = subscription.products[0].product_options.find(
                        (el) => el.display_name === "Quantity"
                    );
                    const subscriptionPlan = subscription.products[0].product_options.find(
                        (el) => el.display_name === "Subscription"
                    );
                    return (
                        <div key={subscription.id} className="treatment__subscription">
                            <Image className="treatment__subscription--thumbnail"
                                 alt="Thumbnail"
                                 src={currentProduct?.images[0]?.url_thumbnail || Thumbnail}/>
                            <div className="treatment__subscription--text">
                                <p className="treatment__subscription--title">
                                    {subscription.products[0].name} -{" "}elke{" "}
                                    {subscription.billing_period} {" "}
                                    {/*{subscription.billing_period_unit}*/}
                                    {t(subscription.billing_period_unit)}
                                </p>
                                <p className="treatment__subscription--subtext">
                                    {dosage ?
                                        `${dosage.display_value} - ${subscriptionPlan.display_value}` :
                                        `${subscriptionPlan.display_value === 'elke maand' ? quantity?.display_value + " " : ""}${subscriptionPlan.display_value}`}
                                </p>
                            </div>
                        </div>
                    );
                })}

                {filteredSubscriptions.length > 1 && subscriptionsCountToShow > 1 && (
                    <button className="see-all" onClick={() => setSubscriptionsCountToShow(1)}>
                        Minder tonen
                    </button>
                )}

                {filteredSubscriptions.length > 1 && filteredSubscriptions.length > subscriptionsCountToShow && (
                    <button className="see-all" onClick={() => setSubscriptionsCountToShow(subscriptions.length || 1)}>
                        Alles
                    </button>
                )}
            </div>

            <div className="treatment__chat">
                <div className="treatment__chat--title">
                    Berichten{" "}
                    {unreadMessagesCount > 0 && (
                        <span className="typography__sub-text treatment__chat--title--count">
                            {unreadMessagesCount}
                        </span>
                    )}
                </div>
                <p className="treatment__chat--description">
                    Voor niet medische vragen kan je bij onze <Link to="" onClick={() => handleButtonClick("klantendienst")}>klantendienst</Link> terecht
                </p>
                <Link className="treatment__chat--chat" to={`/my-account/chat?chatId=${treatmentId}&isTreatment=1`}>
                    <Image className="doctor-photo"
                         src={chatAdminUser?.photo?.content_url || DoctorAvatar}
                         alt={chatAdminUser?.name}/>
                    <div className="treatment__chat--chat--text">
                        <p className="treatment__chat--chat--title">{chatAdminUser?.name}</p>
                    </div>
                    <Image className="mobile-icon" src={ArrowMobile}/>
                    <Image className="desktop-icon" src={ArrowDesktop}/>
                </Link>
            </div>
            <div className="treatment__orders">
                <div className="treatment__chat--order-title">Bestellingen</div>
                {dealsToShow.map(({ data }) => {
                    const currentsub = subscriptions.find(
                        (item) =>
                            item.subscription.id === data.custom_fields.subscription_id
                    );
                    const currentProduct = products.find(
                        (item) =>
                            item.bigcommerce_id === currentsub?.subscription?.products[0]?.product_id
                    );

                    if (!currentsub) {
                        return null;
                    }

                    return (
                        <Link key={data.id} className="treatment__order"
                              to={`/my-account?orderId=${data.custom_fields.order_id}&treatmentID=${treatmentId}`}>
                            <Image
                                className="treatment__order--thumbnail"
                                alt="Thumbnail"
                                src={currentProduct?.images.find((image) => image.is_thumbnail)?.url_standard || currentProduct?.images[0]?.url_standard || Thumbnail}
                            />
                            <div className="treatment__order--text">
                                <p className="treatment__order--title">
                                    {currentsub?.subscription?.products[0]?.name}
                                </p>
                                {
                                    data.estimated_close_date ? (
                                        <p className="treatment__order--description">
                                            verwachte bezorging: {" "}
                                            <span className="treatment__order--description--black">
                                                {new Date(data.estimated_close_date).toLocaleDateString()}
                                            </span>
                                        </p>
                                    ) : (
                                        <p className="treatment__order--description--black">verwacht in 2-3 werkdagen </p>
                                    )
                                }
                            </div>

                            <Image className="mobile-icon" src={ArrowMobile}/>
                            <Image className="desktop-icon" src={ArrowDesktop}/>
                        </Link>
                    );
                })}

                {filteredDeals.length > 1 && dealsCountToShow > 1 && (
                    <button className="see-all" onClick={() => setDealsCountToShow(1)}>
                        Minder tonen
                    </button>
                )}

                {filteredDeals.length > 1 && filteredDeals.length > dealsCountToShow && (
                    <button
                        className="see-all"
                        onClick={() =>
                            setDealsCountToShow(treatmentData?.deals.length || 1)
                        }
                    >
                        Alles
                    </button>
                )}
            </div>

            <div className="treatment__buttons">
                <div className="treatment__chat--order-title">Zelf regelen</div>
                <div className="treatment__buttons-items">
                    <Button onClick={()=>handleButtonClick("Frequentie aanpassen")} value="Frequentie aanpassen" type="transparent"/>
                    <Button onClick={()=>handleButtonClick("Sterkte & aantal wijzigen")} value="Sterkte & aantal wijzigen" type="transparent"/>
                    <Button onClick={()=>handleButtonClick("Behandeling stopzetten")} value="Behandeling stopzetten" type="transparent"/>
                </div>
            </div>

            <div className="treatment__faq">
                <div className="treatment__chat--order-title typography__h3">Veelgestelde vragen</div>
                <Dropdown items={allContentfulBannerUpdated.nodes[0].bulletListText}/>
            </div>
            {showPopUp && (
                        <div
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    hideAndResetPopUp();
                                }
                            }}
                            className="popup-wrapper"
                        >
                            <div className="popup-wrapper__content">
                                <p className="messages__message--problem">Hoe kunnen we je helpen met
                                    <span> {selectedTitle}?</span>
                                </p>
                                <form
                                    onSubmit={editPopUpMessage}
                                    className="popup-wrapper__content__form popup-wrapper__content__form--message"
                                >
                                    <textarea
                                        type="text"
                                        placeholder="..."
                                        name="question"
                                        validation={[validation.notEmpty]}
                                        wrap="soft | hard"
                                    />
                                    <textarea name="type" value={selectedTitle} className='textarea-hidden' />
                                    <div className="buttons">
                                        <Button value="Verzenden" type="dark" isSubmit />
                                        <Button value="Annuleer" type="light" onClick={hideAndResetPopUp} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
        </div>
    );
}

const Treatments = (props) => {
    return (
        <Main>
            <TreatmentsWrapper {...props}/>
        </Main>
    )
};
export default Treatments;
